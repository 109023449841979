import { useTheme } from '@mui/material/styles';
import '@vivino/js-react-common-ui';
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

export interface DeviceBreakpoint {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

interface DeviceBreakpointProviderProps {
  children: React.ReactNode;
  isMobile: boolean;
  isTablet: boolean;
}

export const DeviceBreakpointContext = createContext<DeviceBreakpoint>({
  isMobile: false,
  isTablet: false,
  isDesktop: false,
});

export const DeviceBreakpointProvider = ({
  children,
  isMobile,
  isTablet,
}: DeviceBreakpointProviderProps) => {
  const [isMobileState, setIsMobileState] = useState(isMobile);
  const [isSmallerThanDesktop, setIsSmallerThanDesktop] = useState(isTablet);
  const { breakpoints } = useTheme();

  const MOBILE_MEDIA_QUERY = `(max-width: ${breakpoints.values.sm - 1}px)`;
  const SMALLER_THAN_DESKTOP_QUERY = `(max-width: ${breakpoints.values.lg - 1}px)`;

  useEffect(() => {
    const mobileQuery = globalThis.matchMedia(MOBILE_MEDIA_QUERY);
    const tabletQuery = globalThis.matchMedia(SMALLER_THAN_DESKTOP_QUERY);
    const mobileQueryListener = (e: MediaQueryListEvent) => updateMediaState(e, setIsMobileState);
    const tabletQueryListener = (e: MediaQueryListEvent) =>
      updateMediaState(e, setIsSmallerThanDesktop);
    mobileQuery.addEventListener('change', mobileQueryListener);
    tabletQuery.addEventListener('change', tabletQueryListener);

    setIsMobileState(mobileQuery.matches);
    setIsSmallerThanDesktop(tabletQuery.matches);

    return () => {
      mobileQuery.removeEventListener('change', mobileQueryListener);
      tabletQuery.removeEventListener('change', tabletQueryListener);
    };
  }, []);

  const updateMediaState = (e: MediaQueryListEvent, setter: Dispatch<SetStateAction<boolean>>) => {
    if (e.matches) {
      setter(true);
    } else {
      setter(false);
    }
  };

  const values = {
    isMobile: isMobileState,
    isTablet: !isMobile && isSmallerThanDesktop,
    isDesktop: !isMobile && !isSmallerThanDesktop,
  };

  return (
    <DeviceBreakpointContext.Provider value={values}>{children}</DeviceBreakpointContext.Provider>
  );
};

/*
  Usage on a component:
  import { useBreakpoint } from '@context/DeviceBreakpointContext/DeviceBreakpointContext';

  const { isMobile, isTablet, isDesktop, customQueries } = useBreakpoint({
    isMidDevice: '(min-width: 600px) and (max-width: 899px)',
    isLargeDevice: '(min-width: 1200px)',
    });

    const isMidDevice = customQueries?.isMidDevice;
    const isLargeDevice = customQueries?.isLargeDevice;
*/

export const useBreakpoint = (customQueries?: { [key: string]: string }) => {
  const context = useContext(DeviceBreakpointContext);
  if (context === undefined) {
    throw new Error('useBreakpoint must be used within a DeviceBreakpointProvider');
  }

  const [customQueryStates, setCustomQueryStates] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    if (!customQueries) return;

    const customQueryLists = Object.entries(customQueries).map(([key, query]) => ({
      key,
      queryList: globalThis.matchMedia(query),
    }));

    const customQueryListeners = customQueryLists.map(({ key }) => ({
      key,
      listener: (e: MediaQueryListEvent) => updateCustomQueryState(e, key),
    }));

    customQueryListeners.forEach(({ key, listener }) =>
      customQueryLists.find((q) => q.key === key)?.queryList.addEventListener('change', listener)
    );

    const reducedQueryList = customQueryLists.reduce((acc, { key, queryList }) => {
      acc[key] = queryList.matches;
      return acc;
    }, {} as { [key: string]: boolean });

    setCustomQueryStates(reducedQueryList);

    return () => {
      customQueryListeners.forEach(({ key, listener }) =>
        customQueryLists
          .find((q) => q.key === key)
          ?.queryList.removeEventListener('change', listener)
      );
    };
  }, []);

  const updateCustomQueryState = (e: MediaQueryListEvent, key: string) => {
    setCustomQueryStates((prevState) => ({
      ...prevState,
      [key]: e.matches,
    }));
  };

  return {
    ...context,
    customQueries: customQueryStates,
  };
};
