export function truncate({ text, length }) {
  if (!text) {
    return text;
  }
  const slicedTex = text.slice(0, length);
  if (slicedTex.length < length) {
    return slicedTex;
  }
  return `${slicedTex}...`;
}

export function isMatch(expected, actual) {
  if (typeof actual !== 'string' || typeof expected !== 'string') {
    return false;
  }
  return actual.toUpperCase() === expected.toUpperCase();
}

export function upperFirst(string) {
  if (!string) {
    return string;
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const insertStringAtIndex = (text, string, index) => {
  return text.slice(0, index) + string + text.slice(index);
};

// Escaped meaningful regex characters, so that building the regex will never fail.
// It does not use the not-word-character \W to still allow for local letters like æøå
// For full documentation see: https://stackoverflow.com/a/6969486
export const escapeRegExp = (string) => {
  if (!string) {
    return string;
  }
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};
