import t from 'vivino-js/translationString';

const TRANSLATIONS = {
  bottle: 'components.shared.quantity_picker.bottle',
  bottles: 'components.shared.quantity_picker.bottles',
};

export const bottleTypePlural = ({ bottleTypeId, bottleTypeList = [] }) =>
  bottleTypeId &&
  bottleTypeList.find((bottleType) => bottleType.id === bottleTypeId)?.short_name_plural;

export const bottleTypeSingular = ({ bottleTypeId, bottleTypeList = [] }) =>
  bottleTypeId && bottleTypeList.find((bottleType) => bottleType.id === bottleTypeId)?.short_name;

export const renderBottleType = ({
  value,
  bottleTypeSingular = t(TRANSLATIONS.bottle),
  bottleTypePlural = t(TRANSLATIONS.bottles),
}) => {
  const startsWithLetter = RegExp('^[A-Za-z]');

  return value === 1
    ? `${value} ${startsWithLetter.test(bottleTypeSingular) ? '' : 'x '}${bottleTypeSingular}`
    : `${value} ${startsWithLetter.test(bottleTypePlural) ? '' : 'x '}${bottleTypePlural}`;
};

export const renderBottleTypeByList = ({ value, bottleTypeId = 1, bottleTypeList }) =>
  renderBottleType({
    value,
    bottleTypeSingular: bottleTypeSingular({ bottleTypeId, bottleTypeList }),
    bottleTypePlural: bottleTypePlural({ bottleTypeId, bottleTypeList }),
  });
