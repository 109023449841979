import { getCurrentLocaleWithRegion } from '@helpers/locale';
import { DASH, FREE, INCLUDED, isNumber } from '@vivino/js-web-common';
import { Currency } from '@webtypes/goApi';
import cx from 'classnames';
import React from 'react';

import { priceToString } from '@lib/numbers';

import styles from './price.module.scss';

type ZeroPriceLabel = typeof DASH | typeof FREE | typeof INCLUDED;

export interface PriceProps {
  amount?: number;
  locale?: string;
  className?: string;
  currency?: Currency;
  isNegative?: boolean;
  showZeroAs?: ZeroPriceLabel;
  prependPlus?: boolean;
  strike?: boolean;
  dataTestId?: string;
}

const Price = ({
  amount = 0,
  locale = getCurrentLocaleWithRegion(),
  className,
  currency = {},
  isNegative = false,
  showZeroAs,
  prependPlus = false,
  strike = false,
  dataTestId = null,
}: PriceProps) =>
  isNumber(amount) && (
    <span className={cx({ [styles.strike]: strike }, className)} data-testid={dataTestId}>
      {priceToString({ amount, currency, locale, isNegative, showZeroAs, prependPlus })}
    </span>
  );

export default Price;
