import { isSSR } from '@helpers/ssr';
import { Button, ButtonTheme } from '@vivino/js-react-common-ui';
import { priceToString, useI18N } from '@vivino/js-web-common';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { trackAddToCart } from 'vivino-js/analytics';
import { createCart } from 'vivino-js/api/carts';
import { fetchPriceSubtotal } from 'vivino-js/api/prices';
import { price } from 'vivino-js/apiPropTypes';
import { formSize } from 'vivino-js/commonPropTypes';
import { CartItemSourceContext } from 'vivino-js/context/CartItemSourceContext';
import { MerchandizingCampaignContext } from 'vivino-js/context/MerchandizingContext';
import { getCartItemSource, sanitiseCartItemSource } from 'vivino-js/helpers/cartItemSources';
import { cartUrl } from 'vivino-js/vivinoUrls';

import styles from './addToCartButton.scss';

const TRANSLATIONS = {
  addToCart: 'components.shared.add_to_cart_button.add_to_cart',
};

const AddToCartButton = ({
  price,
  className,
  disabled,
  seenVintageId,
  hidePrice,
  bottleCount,
  onError,
  size,
  dataTestId,
  fullWidth,
}) => {
  const [inProgress, setInProgress] = useState(false);
  const cartItemSourceType = useContext(CartItemSourceContext);
  const campaignId = useContext(MerchandizingCampaignContext);
  const { t } = useI18N();

  if (!price || !price.currency) {
    return null;
  }
  let source = '';
  if (!isSSR) {
    source = sanitiseCartItemSource(getCartItemSource(cartItemSourceType, campaignId));
  }

  const renderButtonContent = () =>
    hidePrice ? (
      t(TRANSLATIONS.addToCart)
    ) : (
      <div className={styles.price}>{priceToString(price)}</div>
    );

  const trackAndRedirect = (cartId, bottleCount) => {
    trackAddToCart({
      source,
      quantity: bottleCount,
      priceType: price.type,
      priceAmount: price.amount,
      priceId: Number(price.id),
      vintageId: Number(seenVintageId),
      currencyCode: price.currency.code,
    });

    window.location.assign(cartUrl({ cartId }));
  };

  const addToCartAfterFetchSubtotal = async () => {
    try {
      const { bottle_count } = await fetchPriceSubtotal({ priceId: price.id });

      const cartData = await createCart({
        source,
        seenVintageId,
        priceId: price.id,
        bottleCount: bottle_count,
      });

      trackAndRedirect(cartData.cart.id, bottle_count);
      setInProgress(false);
    } catch (error) {
      setInProgress(false);
      onError ? onError(error.message) : window.alert(error.message);
    }
  };

  const handleClick = async (event) => {
    event.preventDefault();
    setInProgress(true);

    if (bottleCount) {
      try {
        const cartData = await createCart({
          source,
          bottleCount,
          seenVintageId,
          priceId: price.id,
        });

        trackAndRedirect(cartData.cart.id, bottleCount);
        setInProgress(false);
      } catch (e) {
        addToCartAfterFetchSubtotal();
      }
    } else {
      // if bottleCount is not passed as props
      // need to fetch subtotal to get bottleCount before adding to cart
      addToCartAfterFetchSubtotal();
    }
  };

  return (
    <Button
      size={size}
      disabled={disabled}
      working={inProgress}
      onClick={handleClick}
      fullWidth={fullWidth}
      className={className}
      theme={ButtonTheme.Accent}
      dataTestId={dataTestId || 'addToCart'}
      aria-label={t(TRANSLATIONS.addToCart)}
    >
      {renderButtonContent()}
    </Button>
  );
};

AddToCartButton.propTypes = {
  bottleCount: PropTypes.number,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hidePrice: PropTypes.bool,
  fullWidth: PropTypes.bool,
  price: price,
  seenVintageId: PropTypes.number.isRequired,
  showCartIcon: PropTypes.bool,
  onError: PropTypes.func,
  size: formSize,
  dataTestId: PropTypes.string,
};

export default AddToCartButton;
