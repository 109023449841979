import { renderBottleType } from './bottleTypes';
import t from 'vivino-js/translationString';
import { WineVintageTypes, isMixedVintageWine, isNonVintageWine, isVintageWine } from './wine';

const TRANSLATIONS_PATH = 'components.shared.modals.shops_modal';
const TRANSLATIONS = {
  unspecifiedVintage: 'components.shared.vintage_selection.unspecified_vintage',
  nonVintage: 'components.shared.vintage_selection.non_vintage',
  showingPricesForVintage: `${TRANSLATIONS_PATH}.showing_prices_for_vintage`,
  showingPricesForUnspecifiedVintage: `${TRANSLATIONS_PATH}.showing_prices_for_unspecified_vintage`,
  showingPricesForNonVintage: `${TRANSLATIONS_PATH}.showing_prices_for_non_vintage`,
};

export const vintagesWithRatings = (vintage) => vintage?.statistics?.ratings_count > 0;

// It can be an empty string (for full vintage object) or 0 (for availibility.vintage)
export const isDefaultVintageYear = (year) =>
  !year || String(year).replace(/\./g, '').toUpperCase() === 'NV';

export const isUnknownVintage = (vintage) =>
  !!vintage &&
  isDefaultVintageYear(vintage.year) &&
  (isVintageWine(vintage.wine) || isMixedVintageWine(vintage.wine));

export const isNonVintage = (vintage) => isNonVintageWine(vintage?.wine);

export const isDefaultVintage = (vintage) => isUnknownVintage(vintage) || isNonVintage(vintage);

export const getDisplayVintageYear = (year, vintageType) => {
  if (isDefaultVintageYear(year)) {
    return vintageType === WineVintageTypes.VINTAGE
      ? t(TRANSLATIONS.unspecifiedVintage)
      : t(TRANSLATIONS.nonVintage);
  }
  return year;
};

// This really doesn't deserve to be a helper
// but having it for now until we can get rid of legacy code and commonize components
export const getShowingPricesForVintageText = (year, vintageType) => {
  let text;
  if (isDefaultVintageYear(year)) {
    text =
      vintageType === WineVintageTypes.NON_VINTAGE
        ? t(TRANSLATIONS.showingPricesForNonVintage)
        : t(TRANSLATIONS.showingPricesForUnspecifiedVintage);
  } else {
    text = t(TRANSLATIONS.showingPricesForVintage, { year });
  }
  return `- ${text} -`;
};

export const bottleLabel = ({ bottleType, count, name = '' }) =>
  `${renderBottleType({
    value: count,
    bottleTypeSingular: bottleType?.short_name,
    bottleTypePlural: bottleType?.short_name_plural,
  })} ${name}`;

export function hasBottleShots(vintage) {
  return (
    !!vintage?.image?.variations?.bottle_small &&
    !!vintage?.image?.variations?.bottle_medium &&
    !!vintage?.image?.variations?.bottle_large
  );
}

export function hasLabelShots(vintage) {
  return (
    (!!vintage?.image?.variations?.label_medium && !!vintage?.image?.variations?.label_large) ||
    (!!vintage?.image?.variations?.medium && !!vintage?.image?.variations?.large)
  );
}

// Wine name excluding winery name. Includes vintage year for non-master vintages.
export const vintageDisplayName = (vintage) => {
  if (!vintage) {
    throw new Error('Vintage object is required');
  }
  if (!vintage.wine) {
    throw new Error('Wine is required on a Vintage object');
  }

  if (isUnknownVintage(vintage)) {
    return vintage.wine.name;
  }

  return `${vintage.wine.name} ${yearForDisplay(vintage)}`;
};

const yearForDisplay = (vintage) => {
  if (!vintage.year) {
    return 'N.V.';
  }

  return vintage.year;
};

// Full wine name including winery name. Includes vintage year for non-master vintages.
export const vintageAltText = (vintage) => {
  try {
    const wineryName = vintage.wine?.winery?.name || '';
    const wineDisplayName = vintageDisplayName(vintage);
    if (wineryName && wineDisplayName.indexOf(wineryName) >= 0) {
      return wineDisplayName;
    } else {
      return wineryName + ' ' + wineDisplayName;
    }
  } catch (e) {
    // in case of error being thrown in vintageDisplayName
    // should return default text since alt text is not crucial for customers
    return 'Wine image';
  }
};

// Wine name excluding winery name, and includes N.V. for non-vintage wines.
export const getMasterWineName = (vintage) => {
  // Ideally, just checking `isNonVintage` should be enough,
  // But backend may return vintage.name with numerical vintage year even for vintage_type=1
  // https://tickets.vivino.com/issues/43417
  // so need to check isDefaultVintageYear in addition
  if (isNonVintage(vintage) && isDefaultVintageYear(vintage.year)) {
    return vintageDisplayName(vintage);
  }

  return vintage.wine.name;
};

export const getRegionForVintage = (vintage) =>
  vintage?.wine?.region || vintage?.wine?.winery?.region;

export const getWineryForVintage = (vintage) => vintage?.wine?.winery;

export const getGrapesForVintage = (vintage) => vintage?.grapes || vintage?.wine?.grapes;
